import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import DeliveryAddressDialog from 'components/shared/delivery-dialog/DeliveryAddressDialog';
import DeliveryDialog, {
  DeliveryDialogTypes,
} from 'components/shared/delivery-dialog/DeliveryDialog';

interface IDeliveryProps {
  show: boolean;
  onHide(): void;
  onSubmit(data: any): void;
  goToLocator(data: any): void;
  successEvent?(): any;
  needCheckDelivery?: boolean;
  sorryTitle?: string;
}

function Delivery({
  show,
  onHide,
  onSubmit,
  goToLocator,
  successEvent,
  needCheckDelivery,
  sorryTitle,
}: IDeliveryProps) {
  const [showAddress, setShowAddress] = useState(show);
  const [showEmail, setShowEmail] = useState(false);
  const [loactionData, setLoaction] = useState(null);
  const [isCalifornia, setIsCalifornia] = useState(false);

  useEffect(() => {
    setShowAddress(show);
  }, [show]);

  return (
    <>
      {showAddress && (
        <DeliveryAddressDialog
          successEvent={successEvent}
          needCheckDelivery={needCheckDelivery}
          defaultValue={''}
          submit={onSubmit}
          failSubmit={(data: any, isCalifornia: boolean = false) => {
            setLoaction(data);
            setShowAddress(false);
            setShowEmail(true);
            setIsCalifornia(isCalifornia);
          }}
          close={onHide}
          title="Enter your address here to<br /> see where you can get CLICK!"
        />
      )}

      {showEmail && (
        <DeliveryDialog
          defaultValue={''}
          type={DeliveryDialogTypes.email}
          submit={() => navigate('/account/login')}
          close={() => {
            setShowEmail(false);
            onHide();
          }}
          title={
            (isCalifornia && sorryTitle) ||
            'Sorry. CLICK is currently not available in your state.<br /> Please sign up for alerts so you will be the first to know <br />when we are in your area.<br />'
          }
          changeAddress={() => {
            setShowEmail(false);
            setShowAddress(true);
          }}
          goToLocator={() => {
            goToLocator(loactionData);
            setShowEmail(false);
          }}
        />
      )}
    </>
  );
}

export default Delivery;
