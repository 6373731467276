class Observer implements Framework.Observer {
  private listeners: {
    [event: string]: Function[];
  };

  constructor() {
    this.listeners = {};
  }

  on(event: string, callback: Function): Framework.Observer {
    this.listeners = {
      ...this.listeners,
      [event]: Boolean(this.listeners[event]) ? [...this.listeners[event], callback] : [callback],
    };

    return this;
  }

  off(event: string, callback: Function): Framework.Observer {
    if (this.listeners[event]?.length) {
      this.listeners[event] = this.listeners[event].filter((listener) => listener !== callback);
    }

    return this;
  }

  emit(event: string, ...args: any): Framework.Observer {
    if (this.listeners[event]?.length) {
      this.listeners[event].forEach((listener) => {
        listener(...args);
      });
    }

    return this;
  }
}

export default Observer;
