import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import styles from './AddressDropdown.module.scss';

interface IAddressDropdownProps {
  getPlaceAutocomplete(data: string, callback: (p: any) => void): void;
  value: any;
  onChange(p: any): void;
  placeholder?: string;
}
export interface IAddressOption {
  description: string;
  placeId: string;
}

const wait = 500;
const AddressDropdown = ({
  getPlaceAutocomplete,
  onChange,
  value,
  placeholder,
}: IAddressDropdownProps) => {
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([] as IAddressOption[]);

  let onBlurTimeout: any = null;

  useEffect(() => {
    debouncedLoadOptions(search, () => {});
  }, [search]);

  const InputRef = useRef(null);

  const loadOptions = (inputValue: string, callback: (p: any) => void) => {
    inputValue.length
      ? getPlaceAutocomplete(inputValue, (res) => {
          setOptions(res);
          callback(res);
          setIsLoading(false);
        })
      : setOptions([]);
  };

  const debouncedLoadOptions: any = _.debounce(loadOptions, wait);

  const onSelectItem = (option: IAddressOption) => {
    setSearch('');
    onChange(option);
    setOptions([]);
  };

  const onBlurEvent = () => {
    onBlurTimeout = setTimeout(() => {
      setSearch('');
    }, 100);
  };

  return (
    <>
      <div className={styles.dropdownContainer}>
        <input
          className={classnames(styles.dropdownInput, { [styles.isEmpty]: !value.placeId })}
          name="address"
          autoComplete="address"
          value={search}
          placeholder={placeholder || 'Street Address'}
          onChange={(e) => {
            setSearch(e.target.value);
            setIsLoading(true);
          }}
          onBlur={() => onBlurEvent()}
          onFocus={() => onBlurTimeout && clearTimeout(onBlurTimeout)}
          ref={InputRef}
        />
        <div className={styles.selectedOption} onClick={() => InputRef.current?.focus()}>
          {value.placeId && value.description}
        </div>
        <svg
          className={styles.dropdownIcon}
          height="20"
          width="20"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
        >
          <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>

        <div className={styles.dropdownMenu}>
          {!options.length && !isLoading && (
            <div className={styles.dropdownDefaultText}>No options</div>
          )}
          {!options.length && isLoading && (
            <div className={styles.dropdownDefaultText}>Loading...</div>
          )}
          {options.map((option: IAddressOption) => (
            <div
              className={styles.dropdownOption}
              key={option.placeId}
              onClick={() => onSelectItem(option)}
            >
              {option.description}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AddressDropdown;
