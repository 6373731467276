import ClickAnalytics from './Analytic';

class Analytics {
  public track(event: string): void {
    return ClickAnalytics.getInstance().track(event);
  }

  private page(page: string): void {
    return ClickAnalytics.getInstance().page(page);
  }

  public buyClick() {
    this.page('buy-click');
    this.track('Order Flow: Buy Click');
  }

  public chooseAddress() {
    this.page('choose-address');
    this.track('Order Flow: Choose Address');
  }

  public chooseAddressHome() {
    this.page('home-choose-address');
    this.track('Home Page: Choose Address');
  }

  public changeAddressDelivery() {
    this.page('delivery-change-address');
    this.track('Delivery: Change Address');
  }

  public changeAddressOrder() {
    this.page('cart-change-address');
    this.track('Order Flow: Cart Change Address');
  }

  public chooseAddressNotAvailable() {
    this.page('choose-address-not-available');
    this.track('Choose Address Not Available');
  }

  public continueShopping() {
    this.page('continue-shopping');
    this.track('Order Flow: Continue Shopping');
  }

  public changAddress() {
    this.page('change-address');
    this.track('Order Flow: Change Address');
  }

  public redirectToRetailer(retailerName: string) {
    this.page('redirect-to-retailer-shop');
    this.track(`Order Flow: Redirect to ${retailerName}`);
  }

  public orderNow() {
    this.page('order-now');
    this.track(`Order Flow: Order Now`);
  }

  public addToCart(productName: string) {
    this.page(`add-click-to-cart`);
    this.track(`Order Flow: Add ${productName}`);
  }

  public addToCartQuantity(productName: string, quantity: number) {
    this.page(`add-click-to-cart`);
    this.track(`Order Flow: Add ${quantity} ${productName}`);
  }

  public removeFromCart(productName: string) {
    this.page(`remove-to-cart`);
    this.track(`Order Flow: Remove ${productName}`);
  }

  public removeFromCartQuantity(productName: string, quantity: number) {
    this.page(`remove-to-cart`);
    this.track(`Order Flow: Remove ${quantity} ${productName}`);
  }

  public deleteAddress() {
    this.page(`delete-address`);
    this.track(`Order Flow: Delete Address`);
  }

  public chooseAddressOnCart() {
    this.page(`choose-address`);
    this.track(`Order Flow: Choose Address`);
  }

  public enterApt() {
    this.page(`enter-apt`);
    this.track(`Order Flow: Enter APT`);
  }

  public selectDeliveryDate() {
    this.page(`delivery-date`);
    this.track(`Order Flow: Delivery Date`);
  }

  public selectDeliveryTime() {
    this.page(`delivery-time`);
    this.track(`Order Flow: Delivery Time`);
  }

  public placeOrder() {
    this.page(`place-order`);
    this.track(`Order Flow: Place order`);
  }

  public addAdditionalClick(productName: string) {
    this.page(`add-one-additional-item`);
    this.track(`Order Flow: Add 1 ${productName}`);
  }

  public dispensaryLocator() {
    this.page(`dispensary-locator`);
    this.track(`Order Flow: Dispensary Locator`);
  }

  public viewСart() {
    this.page(`view-cart`);
    this.track(`Order Flow: View Cart`);
  }

  public deliveryPage() {
    this.page(`delivery-page`);
    this.track(`Order Flow: Delivery Page`);
  }

  public signUpStarted() {
    this.page(`order-flow-sign-up-started`);
    this.track(`Order Flow: Sing Up Started`);
  }
}

export default new Analytics();
