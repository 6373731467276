import React from 'react';
import classes from './SeoText.module.scss';

const SeoText: React.FC = () => {
  const [height, changeHeight] = React.useState(0);
  const [isOpen, toggle] = React.useState(false);
  const ref = React.createRef<HTMLDivElement>();

  const changeVisibility = () => {
    if (ref.current instanceof HTMLDivElement) {
      const height = Array.from(ref.current.children).reduce(
        (result, element) =>
          (({ marginBottom, marginTop }) =>
            result +
            element.getBoundingClientRect().height +
            parseFloat(marginBottom) +
            parseFloat(marginTop))(getComputedStyle(element)),
        0,
      );

      changeHeight(isOpen ? 0 : height);
      toggle(!isOpen);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <h1>THC tincture - Wellness Meets High Tech with CLICK Cannabis Spray!</h1>
        <p>THC tincture or a cannabis-infused edible? What would you choose?</p>
        <p>
          As the cannabis and CBD industry continues to grow, so does the lengthy list of options.
          Both are healthier alternatives to smoking, but have more cons than pros when it comes to
          onset of effects, accurate dosing, calories, and mystery ingredients.
        </p>
      </div>
      <div ref={ref} className={classes.content} style={{ height }}>
        <p>
          Fortunately, there’s another route to take when it comes to getting high-quality cannabis
          without the unwanted factors. Meet CLICK– a cannabis spray that not only tackles the
          pitfalls of tinctures and edibles, but gives you everything you want from a healthy
          alternative to smoking cannabis.
        </p>
        <p>
          Created in Los Angeles, CLICK has created its own niche in the booming cannabis market.
          CLICK is a sublingual mouth spray and is consumed orally, similar to how you’d take THC
          oils or THC tinctures. But unlike tinctures, other sublinguals, or edibles, CLICK’s
          NanoTechnology is used to create a supremely fast-acting formula with precise dosing.
        </p>
        <p>
          CLICK has a higher bioavailability than products like edibles or tinctures. Diving into
          the science a bit– our tongues’ mucous membranes are filled with tiny capillaries,
          allowing for quicker absorption into the bloodstream. CLICK’s Nano formula breaks down the
          THC and CBD into extremely small particles which are then merged with water.
        </p>
        <p>
          So when CLICK’s incredibly tiny particles are sprayed under the tongue, the effects are
          felt much quicker, and in this case, it’s 5-8 minutes. Compare that to edibles which can
          take anywhere from 30 minutes to 2 hours, we have a clear winner. CLICK is as discreet as
          an edible, as fast acting as smoking, and better tasting than a THC tincture.
        </p>
        <h2>Cannabis tincture vs. THC spray</h2>
        <p>
          Have you ever looked at a tincture and wondered how much to take? Did you also wonder how
          long it would take to feel the effects? Tinctures are difficult to dose and because your
          body must digest the oil in order to process the effects, it can take quite a while to
          feel anything at all. Plus, they rarely taste good.
        </p>
        <p>CLICK is changing the game.</p>
        <p>
          CLICK delivers a spot on 2.5mg THC spray per single dose. Three out of four of CLICK’s
          products also have CBD, so you can choose which effect you’d like to feel. In each CLICK
          spray, there are 80 2.5mg sprays, which means there are 200 mg of high-quality cannabis
          per bottle. You can get the same convenience as a marijuana tincture but in a delicious,
          fast-acting sublingual spray.
        </p>
        <p>
          There are currently 4 unique CLICK sprays to choose from– GO, RESTORE, CHILL, and DREAM.
          Each name aptly suits the desired effect.
        </p>
        <p>
          <b>GO</b> is all THC with added green tea extract and a mint flavor called MoveMint. This
          all THC spray gives you an uplifting boost of energy and is a great way to get your body
          and mind moving.
        </p>
        <p>
          <b>RESTORE</b> is a CBD-heavy product, with a ratio of THC 1:5 CBD, added Boswellia, and a
          Lemon Ginger Bliss flavor. This is a terrific spray for those who love the effects of CBD,
          with little to no psychoactivity. RESTORE is also great for treating aches and pains as
          Boswellia is a natural antiinflammatory aid.
        </p>
        <p>
          <b>CHILL</b> is a THC 2:1 CBD ratio, added all-natural L-Theanine, and a TropiCali flavor
          which is a blend of pineapple, passionfruit, and mango. CHILL is great for relaxing and
          de-stressing after a long day. The added L-Theanine is known to help with managing anxiety
          and is also great for brain and heart health.
        </p>
        <p>
          <b>DREAM</b> is a THC 1:2 CBD ratio with all-natural melatonin and a Midnight Mint flavor.
          It’s an amazing product for helping you fall and stay asleep. While our bodies naturally
          produce melatonin, sometimes it’s not quite enough to regulate our sleep cycle. DREAM is a
          great bedside companion that helps you get some sweet dreams.
        </p>
        <h2>
          Considering a marijuana tincture?
          <br />
          Here’s why you should choose CLICK instead.
        </h2>
        <p>
          CLICK is the #1 selling cannabis sublingual in California...and there are a handful of
          reasons as to why! Over the last year, CLICK has won multiple awards including the High
          Times Cup, Emerald Cup, and WeedCon Cup.
        </p>
        <p>
          CLICK was built by a strong team of cannabis innovators, industry pioneers, and advocates.
          It’s a brand that values health and values their consumers.
        </p>
        <p>Want more reasons to try CLICK? Each CLICK spray is:</p>
        <ul>
          <li>Precisely dosed (2.5 mg per spray)</li>
          <li>Fast-acting</li>
          <li>Discreet</li>
          <li>Portable</li>
          <li>All-natural</li>
          <li>Great-tasting</li>
          <li>A healthy alternative to smoking</li>
          <li>Built with an ergonomic design and handy keychain hook</li>
          <li>Gluten-free, sugar-free, calorie-free, Non-GMO</li>
        </ul>
        <p>
          Traditional THC tinctures are slow-acting, usually have an oily-residue and unpleasant
          aftertaste, and are certainly not easy to dose. Compared to even the best THC tincture,
          CLICK’s standout features make it the better choice when looking for your new favorite way
          to consume cannabis. While choosing between an edible or a tincture of cannabis has become
          the norm in a dispensary, CLICK’s innovative cannabis sprays offer everything you want and
          nothing that you don’t.
        </p>
        <p>
          CLICK is currently available in nearly 100 shops throughout California and even offers
          direct delivery. Check out www.clickspray.com to learn more and order your new favorite
          cannabis product.
        </p>
      </div>
      <button className={classes.trigger} type="button" onClick={changeVisibility}>
        {isOpen ? 'show less' : 'show more'}
      </button>
    </div>
  );
};

export default SeoText;
