import { MarketService } from '@legalize/sdk';

import { marketId, legalizeApiUrl } from 'services/config';
class Market {
  static instance: Market;
  private marketService: MarketService;
  private market: MarketEntity | undefined;

  constructor() {
    this.marketService = new MarketService(legalizeApiUrl);
  }

  public static getInstance(): Market {
    if (!Market.instance) {
      Market.instance = new Market();
    }
    return Market.instance;
  }

  public getMarket(): Promise<MarketEntity> {
    return new Promise((resolve) => {
      if (this.market?.id) {
        resolve(this.market);
      } else {
        this.marketService.getMarketInfo(marketId).then((res: { entity: MarketEntity }) => {
          this.market = res?.entity;
          resolve(this.market);
        });
      }
    });
  }

  public isActiveMarket(): Promise<boolean> {
    return new Promise((resolve) => {
      this.getMarket().then((result) => {
        resolve(result?.isActive);
      });
    });
  }

  public getDefaultPlaceId(): Promise<string> {
    return new Promise((resolve) => {
      this.getMarket().then((result) => {
        resolve(result?.defaultPlaceId);
      });
    });
  }
}

export default Market;
