import React from 'react';
import { Link } from 'gatsby';
import { ScrollPercentage } from 'react-scroll-percentage';
import VizSensor from 'react-visibility-sensor';
import { NotificationContainer } from 'react-notifications';
import classnames from 'classnames';

import RewardsPopUp from '../../shared/rewards/rewardsPopUp';
import AgeGate from 'components/shared/age-gate/AgeGate';
import TermsOfService from 'components/terms-of-service/TermsOfService';
import PrivacyPolicy from 'components/privacy-policy/PrivacyPolicy';
import SideNav from 'components/side-nav/SideNav';
import TopButton from 'components/top-button/TopButton';
import MobileNav from 'components/mobile-nav/MobileNav';
import ButtonBuy from 'components/shared/button-buy/ButtonBuy';

import styles from './Layout.module.scss';
import textLogo from 'images/Logo-Type-White.svg';
import logo from 'images/Logo-Mark-White.svg';
import email from 'images/Social-Email-White.svg';
import fb from 'images/Social-Facebook-White.svg';
import inst from 'images/Social-Instagram-White.svg';
import tw from 'images/Social-Twitter-White.svg';
import spotify from 'images/Spotify_Icon_CMYK_White.png';
import SeoText from 'components/index/SeoText';

export default class Layout extends React.Component<any> {
  state: any;
  ref: any;
  footer: any;
  className?: string;

  constructor(props: any) {
    super(props);

    this.state = {
      percentage: 0,
      footerIsVisible: false,
      activeUrl: props.location || '',
      hideTermsOfService: true,
      hidePrivacyPolicy: true,
      isRewardsPopUpShown: false,
    };
  }
  setRewardsPopUpShown = () => {
    setTimeout(() => {
      this.setState({ isRewardsPopUpShown: true });
    }, 5000);
  };

  render() {
    const {
      children,
      dispensariesBlockRef,
      className,
      availableLinks,
      isSideMenu = true,
      location,
    } = this.props;

    const { isRewardsPopUpShown } = this.state;

    return (
      <ScrollPercentage
        onChange={(percentage) => this.setState({ percentage: percentage.toPrecision(3) })}
      >
        <div className={styles.mainContainer}>
          <NotificationContainer />

          <AgeGate setRewardsPopUpShown={this.setRewardsPopUpShown} />

          {isRewardsPopUpShown && <RewardsPopUp />}

          <TermsOfService
            hide={this.state.hideTermsOfService}
            hideTermsOfService={() => this.setState({ hideTermsOfService: true })}
          ></TermsOfService>
          <PrivacyPolicy
            hide={this.state.hidePrivacyPolicy}
            hidePrivacyPolicy={() => this.setState({ hidePrivacyPolicy: true })}
          ></PrivacyPolicy>

          <div
            className={classnames(styles.childrenWrapper, className)}
            ref={(ctx) => (this.ref = ctx)}
          >
            {isSideMenu && <ButtonBuy />}

            {isSideMenu && (
              <Link className={styles.logo} to="https://clickspray.com/">
                <img src={textLogo} alt="Text Logo"></img>
              </Link>
            )}
            {/* <div className={styles.whiteBloc}></div> */}

            {children}

            {isSideMenu && (
              <SideNav
                dispensariesBlockRef={dispensariesBlockRef}
                percentage={this.state.percentage}
                footerIsVisible={this.state.footerIsVisible}
                activeUrl={this.state.activeUrl}
                setNavAbsolute={this.props.setNavAbsolute}
                availableLinks={availableLinks}
              ></SideNav>
            )}

            {isSideMenu && (
              <TopButton
                activeUrl={this.state.activeUrl}
                setNavAbsolute={this.props.setNavAbsolute}
              ></TopButton>
            )}

            {isSideMenu && (
              <MobileNav
                percentage={this.state.percentage}
                activeUrl={this.state.activeUrl}
                availableLinks={availableLinks}
              ></MobileNav>
            )}
          </div>

          <VizSensor
            partialVisibility
            scrollCheck={true}
            scrollThrottle={10}
            onChange={(isVisible) => {
              this.setState({ footerIsVisible: isVisible });
            }}
          >
            <>
              {location == '/' && <SeoText />}
              <footer className={styles.footer} ref={this.footer}>
                <div className={styles.desktopFooter}>
                  <div className={styles.logoBox}>
                    <img src={textLogo} alt="Text Logo"></img>
                    <div className={styles.whiteLineBox}>
                      <div className={styles.whiteLine}></div>
                      <span>&copy; CLICK SPRAY</span>
                    </div>
                  </div>
                  <div className={styles.linksBox}>
                    <div className={styles.rightBorderedContainer}>
                      <ul>
                        <li
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              hideTermsOfService: !this.state.hideTermsOfService,
                            })
                          }
                        >
                          Terms of Service
                        </li>
                        <li
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.setState({
                              hidePrivacyPolicy: !this.state.hidePrivacyPolicy,
                            })
                          }
                        >
                          Privacy Policy
                        </li>
                      </ul>
                    </div>
                    <div className={styles.rightBorderedContainer}>
                      <ul>
                        <li>
                          <Link to="/faq">FAQ</Link>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/clickspray/"
                            rel="noreferrer"
                            target="_blank"
                          >
                            Careers
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className={styles.rightBorderedContainer}>
                      <ul>
                        <li>
                          <Link to="/resources"> Resources </Link>
                        </li>
                        <li>
                          <Link to="/press">Press</Link>
                        </li>
                      </ul>
                    </div>
                    <div className={styles.rightBorderedContainer}>
                      <ul>
                        <li>Contact Us</li>
                        <li>
                          <a href="tel:855-739-5771">855-739-5771</a>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <p className={styles.joinText}>join click rewards</p>
                    </div>
                  </div>
                  <div className={styles.socialBox}>
                    <Link to="https://clickspray.com/">
                      <img src={logo} alt="Logo"></img>
                    </Link>
                    <a href="mailto:hello@clickspray.com" rel="noreferrer" target="_blank">
                      <img src={email} alt="email"></img>
                    </a>
                    <a href="https://www.facebook.com/clicksprays" rel="noreferrer" target="_blank">
                      <img src={fb} alt="facebook logo"></img>
                    </a>
                    <a
                      href="https://www.instagram.com/clickspray/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={inst} alt="instagram logo"></img>
                    </a>
                    <a href="https://twitter.com/clicksprays" rel="noreferrer" target="_blank">
                      <img src={tw} alt="twitter logo"></img>
                    </a>
                    <a
                      href="https://open.spotify.com/user/uatzqwongww92qhgpeif7s10j?si=-zWkCbn0TLiIUZX_GoRQjA"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={spotify} alt="spotify logo" style={{ height: '20px' }}></img>
                    </a>
                  </div>
                </div>

                <div className={styles.mobileFooter}>
                  <div className={styles.topBox}>
                    <img src={textLogo} alt="Text Logo"></img>
                  </div>
                  <div className={styles.middleBox}>
                    <ul>
                      <li
                        onClick={() =>
                          this.setState({
                            hideTermsOfService: !this.state.hideTermsOfService,
                          })
                        }
                      >
                        Terms of Service
                      </li>
                      <li
                        onClick={() =>
                          this.setState({
                            hidePrivacyPolicy: !this.state.hidePrivacyPolicy,
                          })
                        }
                      >
                        Privacy Policy
                      </li>
                      <li>
                        <Link to="/faq">FAQ</Link>
                      </li>
                      <li>
                        <Link to="/resources"> Resources </Link>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/clickspray/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          Careers
                        </a>
                      </li>
                      <li>
                        <Link to="/press">Press</Link>
                      </li>
                      <li>Contact Us</li>
                      <li>
                        <a href="tel:855-739-5771">855-739-5771</a>
                      </li>
                    </ul>
                    <div>
                      <span>&copy; CLICK SPRAY</span>
                    </div>
                  </div>
                  <div className={styles.bottomBox}>
                    <Link to="https://clickspray.com/">
                      <img src={logo} alt="Logo"></img>
                    </Link>
                    <a href="mailto:hello@clickspray.com" rel="noreferrer" target="_blank">
                      <img src={email} alt="email"></img>
                    </a>
                    <a href="https://www.facebook.com/clicksprays" rel="noreferrer" target="_blank">
                      <img src={fb} alt="facebook logo"></img>
                    </a>
                    <a
                      href="https://www.instagram.com/clickspray/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={inst} alt="instagram logo"></img>
                    </a>
                    <a href="https://twitter.com/clicksprays" rel="noreferrer" target="_blank">
                      <img src={tw} alt="twitter logo"></img>
                    </a>
                    <a
                      href="https://open.spotify.com/user/uatzqwongww92qhgpeif7s10j?si=-zWkCbn0TLiIUZX_GoRQjA"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={spotify} alt="spotify logo" style={{ width: '20px' }}></img>
                    </a>
                  </div>
                </div>
              </footer>
            </>
          </VizSensor>
        </div>
      </ScrollPercentage>
    );
  }
}