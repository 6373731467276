import { Link } from 'gatsby';
import React from 'react';
import classnames from 'classnames';

import useAuth from '../../hooks/useAuth';
import { RewardsUserType } from '../../services/enums';
import styles from './SideNav.module.scss';
import { getLinkColorMembership, getLinkColorDesktop } from './menu-color';

interface LinkData {
  homePage: string;
  productsPage: string;
  blogPage: string;
  postPage: string;
  eventsPage: string;
}

const navLinksColorSettings: {
  [key: string]: LinkData;
} = {
  homeLink: {
    homePage: '(percentage > 0.306 && percentage <= 0.415) || percentage >= 0.558',
    productsPage: '(percentage > 0.330 && percentage <= 0.682)',
    blogPage: 'percentage > 0.479',
    postPage: 'percentage > 0.545',
    eventsPage: 'percentage > 0.535',
  },
  aboutLink: {
    homePage: '(percentage > 0.283 && percentage <= 0.39) || percentage >= 0.532',
    productsPage: '(percentage > 0.306 && percentage <= 0.655)',
    blogPage: 'percentage > 0.438',
    postPage: 'percentage > 0.495',
    eventsPage: 'percentage > 0.485',
  },
  productsLink: {
    homePage: '(percentage > 0.252 && percentage <= 0.36) || percentage >= 0.502',
    productsPage: '(percentage > 0.274 && percentage <= 0.622)',
    blogPage: 'percentage > 0.387',
    postPage: 'percentage > 0.435',
    eventsPage: 'percentage > 0.425',
  },
  blogLink: {
    homePage: '(percentage > 0.230 && percentage <= 0.335) || percentage >= 0.477',
    productsPage: '(percentage > 0.248 && percentage <= 0.597)',
    blogPage: 'percentage > 0.347',
    postPage: 'percentage > 0.390',
    eventsPage: 'percentage > 0.380',
  },
  eventsLink: {
    homePage: '(percentage > 0.200 && percentage <= 0.308) || percentage >= 0.45',
    productsPage: '(percentage > 0.220 && percentage <= 0.569)',
    blogPage: 'percentage > 0.302',
    postPage: 'percentage > 0.329',
    eventsPage: 'percentage > 0.329',
  },
};

interface Props {
  activeUrl: string;
  percentage: number;
  footerIsVisible: boolean;
  setNavAbsolute: boolean;
  dispensariesBlockRef: React.RefObject<any>;
  availableLinks?: ILink[];
}

interface ILink {
  name: string;
  onClick(): void;
}

const SideNav: React.FC<Props> = ({
  footerIsVisible,
  setNavAbsolute,
  percentage,
  activeUrl,
  dispensariesBlockRef,
  availableLinks,
}: Props) => {
  const {
    state: { profile },
  } = useAuth();

  const locatorOnSamePage = activeUrl === '/' || activeUrl.includes('/about');
  const showAccountMenu = activeUrl.includes('/account');

  const navPositionStyles = () => {
    if (
      activeUrl.includes('/blog') ||
      activeUrl.includes('/events') ||
      activeUrl.includes('/account') ||
      activeUrl.includes('/resources')
    ) {
      if (footerIsVisible) {
        return {
          position: 'absolute',
          bottom: 0,
          paddingBottom: '10px',
          top: 'unset',
        };
      }
    } else {
      if (setNavAbsolute) {
        if (activeUrl.includes('/products')) {
          return {
            position: 'absolute',
            bottom: '50vw',
            paddingBottom: '10px',
            top: 'unset',
          };
        } else if (activeUrl.includes('/about')) {
          return {
            position: 'absolute',
            bottom: '80vw',
            paddingBottom: '10px',
            top: 'unset',
          };
        } else {
          return {
            position: 'absolute',
            bottom: '120vw',
            paddingBottom: '10px',
            top: 'unset',
          };
        }
      }
    }
    return { position: 'fixed', top: '45px' };
  };

  const getLinkColor = (link: string) => {
    if (activeUrl === '/') {
      if (eval(navLinksColorSettings[link].homePage)) {
        return '#FF3800';
      } else {
        return 'white';
      }
    } else if (activeUrl.includes('/account')) {
      return getLinkColorMembership(link, percentage);
    } else if (activeUrl.includes('/faq')) {
      return getLinkColorDesktop('faq', link, percentage);
      /*if (eval(navLinksColorSettings[link].homePage)) {
        return '#FF3800';
      } else {
        return 'white';
      }*/
    } else if (activeUrl.includes('/about')) {
      if (percentage > 0) {
        return '#FF3800';
      }
    } else if (activeUrl.includes('/products')) {
      if (eval(navLinksColorSettings[link].productsPage)) {
        if (activeUrl.includes('/go')) {
          return '#FF3800';
        } else if (activeUrl.includes('/restore')) {
          return '#6ADF8B';
        } else if (activeUrl.includes('/chill')) {
          return '#5E91FF';
        } else if (activeUrl.includes('/dream')) {
          return '#3308C2';
        }
      } else {
        return 'white';
      }
    } else if (activeUrl.includes('/blog')) {
      if (!!activeUrl.replace('/blog', '')) {
        if (eval(navLinksColorSettings[link].postPage)) {
          return 'black';
        } else {
          return 'white';
        }
      } else {
        if (eval(navLinksColorSettings[link].blogPage)) {
          return 'black';
        } else {
          return 'white';
        }
      }
    } else if (activeUrl.includes('/events')) {
      if (eval(navLinksColorSettings[link].eventsPage)) {
        return 'black';
      } else {
        return 'white';
      }
    }
    return '';
  };

  const homeLinkColor = getLinkColor('homeLink');
  const aboutLinkColor = getLinkColor('aboutLink');
  const productsLinkColor = getLinkColor('productsLink');
  const blogLinkColor = getLinkColor('blogLink');
  const eventsLinkColor = getLinkColor('eventsLink');

  return (
    <nav className={styles.navBar} style={navPositionStyles() as any}>
      <ul>
        <li>
          <Link to="https://clickspray.com" className={styles.active}>
            <svg
              style={{ transform: 'rotate(180deg)' }}
              data-name="Logo/Mark/White"
              width="33"
              height="35"
              viewBox="0 0 33 35"
            >
              <g id="Click_logo-mark" transform="translate(0.254)">
                <path
                  id="Path"
                  d="M20.777,27.295a4.816,4.816,0,0,1-2.394-.627l-.16-.078a.477.477,0,0,1-.186-.131l-.106-.078a30.625,30.625,0,0,0-3.857-2.586,27.894,27.894,0,0,0-4.23-1.985l-.133-.052a.8.8,0,0,1-.213-.1l-.133-.078L9.231,21.5a4.792,4.792,0,0,1,0-8.358l.053-.026h0l.133-.078c.053-.026.133-.052.186-.078l.133-.052a30.665,30.665,0,0,0,4.23-1.985,29.233,29.233,0,0,0,3.884-2.612l.133-.1c.053-.052.133-.078.186-.131l.106-.052H18.3a5.041,5.041,0,0,1,2.447-.653,4.866,4.866,0,0,1,4.523,2.978,1.221,1.221,0,0,0,1.7.575c1.463-.81,3.591-1.985,4.895-2.69a1.2,1.2,0,0,0,.372-1.776,17.469,17.469,0,0,0-6.757-4.937h0l-.16-.078A19.139,19.139,0,0,0,17.665,0a17.766,17.766,0,0,0-12.5,4.937A17.153,17.153,0,0,0,0,17.4V17.6A17.153,17.153,0,0,0,5.161,30.063,17.766,17.766,0,0,0,17.665,35a19.139,19.139,0,0,0,7.662-1.437l.16-.078h0a17.862,17.862,0,0,0,6.757-4.937,1.2,1.2,0,0,0-.372-1.776c-1.33-.731-3.538-1.959-5.028-2.769a1.236,1.236,0,0,0-1.7.522A4.706,4.706,0,0,1,20.777,27.295Z"
                  fill={homeLinkColor || 'white'}
                />
                <path
                  id="Path-2"
                  data-name="Path"
                  d="M12.185,1.851A1.82,1.82,0,0,0,10.379,0a1.726,1.726,0,0,0-.9.257l-.05.026h0A30.677,30.677,0,0,1,5.39,3.136,30.208,30.208,0,0,1,.953,5.295h0L.9,5.321a1.858,1.858,0,0,0,0,3.187l.125.077h0a30.208,30.208,0,0,1,4.438,2.159A33.245,33.245,0,0,1,9.5,13.571h0a1.78,1.78,0,0,0,2.658-1.594v-.129a31.059,31.059,0,0,1-.376-5.012,31.094,31.094,0,0,1,.4-4.986Z"
                  transform="translate(10.154 10.352)"
                  fill={homeLinkColor || 'white'}
                />
              </g>
            </svg>
          </Link>
        </li>

        {availableLinks ? (
          availableLinks.map((item, index) => (
            <li key={`link_${index}`} onClick={item.onClick}>
              <div className={classnames(styles.locateLink)}>
                <span style={{ color: getLinkColor(item.name) }}>{item.name}</span>
                <div
                  className={styles.triangle}
                  style={{ borderRightColor: getLinkColor(item.name) }}
                />
              </div>
            </li>
          ))
        ) : showAccountMenu ? (
          <React.Fragment>
            {profile?.rewardsUserType !== RewardsUserType.consumer && (
              <DesktopLink
                partiallyActive={false}
                url="/account/product-info"
                title="PRODUCT INFO"
                color={productsLinkColor}
              />
            )}

            {profile?.rewardsUserType !== RewardsUserType.consumer && (
              <DesktopLink
                partiallyActive={false}
                url="/account/invite-customer"
                title="INVITE"
                color={productsLinkColor}
              />
            )}

            <DesktopLink
              partiallyActive={false}
              url="/account"
              title="PROFILE"
              color={eventsLinkColor}
            />

            {!profile?.rewardsUserType && (
              <React.Fragment>
                <DesktopLink
                  url="/account"
                  partiallyActive={false}
                  title=""
                  color={blogLinkColor}
                />
                <DesktopLink
                  url="/account"
                  partiallyActive={false}
                  title=""
                  color={blogLinkColor}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DesktopLink
              url="https://clickspray.com/go/"
              title="PRODUCTS"
              color={productsLinkColor}
              activeUrl={activeUrl}
            />
            {locatorOnSamePage ? (
              <li
                onClick={() => {
                  dispensariesBlockRef.current.scrollIntoView({
                    behavior: 'smooth',
                  });
                }}
              >
                <div className={styles.locateLink}>
                  <span style={{ color: blogLinkColor }}>LOCATE</span>
                  <div
                    className={styles.triangle}
                    style={{ borderRightColor: blogLinkColor }}
                  ></div>
                </div>
              </li>
            ) : (
              <DesktopLink url="/#dispensaries" title="LOCATE" color={blogLinkColor} />
            )}
            {/* <DesktopLink url="/blog" title="BLOG" color={this.eventsLinkColor} /> */}
            <DesktopLink url="https://clickspray.com/about-page/" title="ABOUT" color={aboutLinkColor} />
          </React.Fragment>
        )}
      </ul>
    </nav>
  );
};

export default SideNav;

const DesktopLink = ({ url, title, color, activeUrl, partiallyActive = true }: any) => {
  return (
    <li>
      <Link
        to={url}
        className={activeUrl && activeUrl.includes('products') ? styles.active : ''}
        partiallyActive={partiallyActive}
        activeClassName={styles.active}
        style={{ color }}
      >
        <span>{title}</span>
        <div className={styles.triangle} style={{ borderRightColor: color }}></div>
      </Link>
    </li>
  );
};
