import React, { useState } from 'react';
import classnames from 'classnames';
import {
  PlaceService,
  MenuService,
  PlaceDetailsEntity,
  MenuItemGroupEntity,
  PlacePredictionEntity,
} from '@legalize/sdk';

import { generateUUID } from 'services/helpers';
import OrderAnalytics from 'core/framework/analytic/OrderFlow';
import { marketId, brandIds, categoryClickIds, legalizeApiUrl } from 'services/config';

import AddressDropdown from 'components/shared/address-dropdown/AddressDropdown';
import selectCustomStyle from './SelectCustomStyle';

import CrossImageFile from 'images/cross.svg';

import styles from './DeliveryDialog.module.scss';

interface IDialogProps {
  className?: string;
  defaultValue: any;
  title: string;
  submit(data?: any): void;
  failSubmit(event?: React.SyntheticEvent, isCalifornia?: boolean): void;
  close(event?: React.SyntheticEvent): void;
  successEvent?(): any;
  needCheckDelivery?: boolean;
}

const AddressDialog: React.FC<IDialogProps> = ({
  className,
  defaultValue,
  submit,
  failSubmit,
  title,
  close,
  successEvent,
  needCheckDelivery,
}) => {
  const [value, setValue] = useState(defaultValue);
  const placeService = new PlaceService(legalizeApiUrl);
  const menuService = new MenuService(legalizeApiUrl);
  const sessionId = generateUUID();

  const getPlaceAutocomplete = (search: string, callback: (p: PlacePredictionEntity[]) => void) =>
    placeService
      .search(search, sessionId)
      .then((resultData: PlacePredictionEntity[]) => Promise.resolve(callback(resultData || [])));

  const checkLocation = () =>
    placeService
      .getDetailsByPlaceId(value.placeId)
      .then((resultData: PlaceDetailsEntity) => {
        if (resultData.address.includes('CA') && resultData.address.includes('USA')) {
          OrderAnalytics.continueShopping();
          menuService
            .getGroupsByBrandAndCategoryIds(marketId, value.placeId, brandIds, categoryClickIds)
            .then((menuResultData: MenuItemGroupEntity[]) => {
              if (menuResultData[0]?.skus) {
                successEvent && successEvent();
                submit(resultData.toJSON());
                return;
              } else {
                if (!needCheckDelivery) {
                  submit(resultData.toJSON());
                } else {
                  failSubmit(resultData.toJSON(), true);
                }
              }
            });
        } else {
          failSubmit(resultData.toJSON(), false);
          OrderAnalytics.chooseAddressNotAvailable();
        }
      })
      .catch(console.log);

  return (
    <div className={classnames(styles.dialogBackPlate, className)}>
      <div className={styles.content}>
        <div onClick={close} className={styles.close}>
          <img src={CrossImageFile} alt="close" />
        </div>
        <div className={styles.dialog}>
          <svg
            className={styles.logo}
            xmlns="http://www.w3.org/2000/svg"
            width="43"
            height="46"
            fill="none"
          >
            <path
              fill="#5E91FF"
              d="M27.4 35.87a6.23 6.23 0 01-3.12-.82l-.2-.1a.62.62 0 01-.25-.18l-.14-.1a39.9 39.9 0 00-10.53-6l-.18-.07c-.1-.04-.17-.07-.27-.14l-.18-.1-.17-.1a6.33 6.33 0 010-11l.07-.03.17-.1.24-.1.18-.07a39.81 39.81 0 0010.57-6.04l.17-.14c.07-.07.18-.1.25-.17l.14-.07h.03a6.34 6.34 0 019.08 3.06c.35.85 1.42 1.2 2.22.75 1.9-1.06 4.68-2.61 6.38-3.54a1.58 1.58 0 00.48-2.33c-3.08-3.98-7.76-6.08-8.8-6.49l-.2-.1a24.75 24.75 0 00-10-1.89C17.12 0 11.33 2.3 7.07 6.49A22.64 22.64 0 00.33 22.86v.28c0 6.31 2.4 12.15 6.73 16.37A23.04 23.04 0 0023.35 46c3.78 0 7.04-.62 9.98-1.89l.21-.1c1-.41 5.68-2.5 8.8-6.49.6-.75.35-1.85-.48-2.33-1.73-.96-4.61-2.58-6.55-3.64a1.6 1.6 0 00-2.22.68 6.13 6.13 0 01-5.69 3.64z"
            />
            <path
              fill="#5E91FF"
              d="M29.44 16.04a2.38 2.38 0 00-2.35-2.43c-.4 0-.79.1-1.18.33l-.07.04a39.98 39.98 0 01-11.04 6.58l-.06.04a2.45 2.45 0 000 4.19l.16.1c1.83.7 3.79 1.65 5.78 2.84a43.32 43.32 0 015.26 3.71c.36.2.75.3 1.15.3 1.2 0 2.32-1 2.32-2.4v-.16c-.33-2-.5-4.22-.5-6.59 0-2.3.2-4.56.53-6.55z"
            />
          </svg>

          <p className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />

          <div className={styles.inputLabel}>
            <AddressDropdown
              onChange={(option: any) => {
                setValue(option);
              }}
              value={value}
              customStyle={selectCustomStyle}
              getPlaceAutocomplete={getPlaceAutocomplete}
              placeholder="enter here…"
            />
            <span className={styles.inputTitle}>address</span>
          </div>

          <button disabled={!value} className={styles.button} onClick={checkLocation}>
            continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddressDialog;
