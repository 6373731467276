interface LinkData {
  home: string;
  menu: string;
}

const navLinksColorSettingsMembership: {
  [key: string]: { [key: string]: string };
} = {
  desktop: {
    homeLink: '(percentage < 0.140) || (percentage > 0.452 && percentage <= 0.602)',
    register: 'percentage > 0.440 && percentage <= 0.590',
    share: 'percentage > 0.425 && percentage <= 0.575',
    about: 'percentage > 0.415 && percentage <= 0.565',
    rewards: 'percentage > 0.405 && percentage <= 0.550',
    profile: 'percentage > 0.390 && percentage <= 0.535',
  },
  tab: {
    homeLink: '(percentage < 0.115) || (percentage > 0.540 && percentage <= 0.647)',
    register: 'percentage > 0.530 && percentage <= 0.637',
    share: 'percentage > 0.520 && percentage <= 0.625',
    about: 'percentage > 0.510 && percentage <= 0.615',
    rewards: 'percentage > 0.497 && percentage <= 0.605',
    profile: 'percentage > 0.485 && percentage <= 0.592',
  },
};

const navLinksColorDesktopSettings: {
  [key: string]: any;
} = {
  faq: {
    homeLink: 'percentage < 0.360',
    productsLink: 'percentage < 0.320',
    blogLink: 'percentage < 0.2880',
    aboutLink: 'percentage < 0.250',
  },
};

const navLinksColorMobileSettings: {
  [key: string]: LinkData;
} = {
  blog: {
    home: '(percentage < 0.425)',
    menu: '(percentage < 0.425)',
  },
  events: {
    home: '(percentage < 0.425)',
    menu: '(percentage < 0.425)',
  },
  account: {
    home: '(percentage < 0.0985) || (percentage > 0.390  && percentage < 0.455 )',
    menu: '(percentage < 0.0985) || (percentage > 0.390  && percentage < 0.400 )',
  },
  products: {
    home: '(percentage < 0.270) || (percentage > 0.695  && percentage < 0.805 )',
    menu: '(percentage < 0.335) || (percentage > 0.695  && percentage < 0.805 )',
  },
  resources: {
    home: '(percentage < 0.290)',
    menu: '(percentage < 0.290)',
  },
  about: {
    home: '(percentage < 0.165)',
    menu: '(percentage < 0.230)',
  },
  faq: {
    home: '(percentage < 0.165)',
    menu: '(percentage < 0.230)',
  },
  home: {
    home: '(percentage < 0.205) || (percentage > 0.325  && percentage < 0.435 )',
    menu: '(percentage < 0.205) || (percentage > 0.325  && percentage < 0.435 )',
  },
};

export const getLinkColorMembership = (link: string, percentage: number) => {
  let size = window.innerWidth > 1200 ? 'desktop' : 'tab';
  if (eval(navLinksColorSettingsMembership[size][link])) {
    return '#ffffff';
  } else {
    return '#5e91ff';
  }
};

export const getLinkColorDesktop = (page: string, link: string, percentage: number) => {
  if (eval(navLinksColorDesktopSettings[page][link])) {
    return '#ffffff';
  } else {
    return '#5e91ff';
  }
};

export const getLinkColorMobile = (page: string, link: 'home' | 'menu', percentage: number) => {
  if (eval(navLinksColorMobileSettings[page][link])) {
    return '#ffffff';
  } else {
    return '#5e91ff';
  }
};
