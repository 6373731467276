/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export default {
  container: (provided: any) => ({
    ...provided,
    marginTop: '0vw',
    border: 'none',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: 'none',
    borderRadius: 0,
    outline: 0,
    minHeight: '56px',
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    borderBottom: '1px #fff solid',
    padding: '0 0 10px',
    marginBottom: '4px',
    mixBlendMode: 'normal',
    ':hover': {
      border: 'none',
      borderBottom: '1px #fff solid',
      boxShadow: 'none',
    },
    '@media (max-width: 700px)': {
      minHeight: '30px',
    },
  }),
  option: (provided: any) => ({
    ...provided,
    backgroundColor: 'white',
    color: '#626262',
    cursor: 'pointer',
    fontSize: '0.875rem',
    ':hover': {
      backgroundColor: '#f2f2f2',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: 'acumin-pro-extra-condensed',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '30px',
    lineHeight: '100%',
    color: '#ffffff',
    '@media (max-width: 700px)': {
      fontSize: '24px',
    },
  }),
  input: (provided: any) => ({
    ...provided,
    outline: 0,
    fontFamily: 'acumin-pro-extra-condensed',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '30px',
    lineHeight: '100%',
    color: '#ffffff',
    width: '100%',
    '@media (max-width: 700px)': {
      fontSize: '24px',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    outline: 0,
    fontFamily: 'acumin-pro-extra-condensed',
    fontStyle: 'italic',
    fontWeight: 'normal',
    fontSize: '30px',
    lineHeight: '100%',
    color: '#ffffff',
    opacity: '0.35',
    textAlign: 'left',
    '@media (max-width: 850px)': {
      marginRight: '20px',
    },
    '@media (max-width: 700px)': {
      fontSize: '24px',
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: '0px',
    borderRadius: 0,
    '@media (max-width: 850px)': {
      marginTop: '5px',
    },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};
