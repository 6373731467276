import moment from 'moment';

export const isTwentyOneYear = (date: string): boolean => {
  return moment().diff(DOBToDate(date), 'years') >= 21;
};

export function getDistanceFromLatLonInKm(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number {
  const deg2rad = (deg: number) => deg * (Math.PI / 180);

  const R = 6371;
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

export function validate(rules: any): string {
  return Object.keys(rules).find((key) => rules[key] === true) || '';
}

export function DOBToDate(dob: string): Date {
  return moment(dob, 'MM-DD-YYYY').toDate();
}

export function dateToDOB(date?: Date): string {
  return moment(date).format('MM-DD-YYYY');
}

export function generateUUID() {
  function chr4() {
    return Math.random().toString(16).slice(-4);
  }
  return (
    chr4() + chr4() + '-' + chr4() + '-' + chr4() + '-' + chr4() + '-' + chr4() + chr4() + chr4()
  );
}
export function alphabeticallySort(a: { name: string }, b: { name: string }) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}
