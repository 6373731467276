import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import React from 'react';

import useAuth from '../../hooks/useAuth';
import styles from './TopButton.module.scss';
import AuthAnalytics from '../../core/framework/analytic/Auth';

interface Props {
  activeUrl: string;
  setNavAbsolute: boolean;
}

const TopButton: React.FC<Props> = ({ activeUrl, setNavAbsolute }: Props) => {
  const { pathname } = useLocation();
  const {
    state: { isPending, isAuthenticated },
    logout,
  } = useAuth();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buttonsPositionStyles = (): any => {
    if (setNavAbsolute) {
      if (activeUrl === '/') {
        return {
          position: 'absolute',
          bottom: 'calc(120vw + 100vh - 70px)',
          top: 'unset',
        };
      } else {
        return {
          position: 'absolute',
          bottom: 'calc(90vw + 100vh - 70px)',
          top: 'unset',
        };
      }
    }
    return { position: 'fixed', top: '40px' };
  };

  const onClick = async () => {
    if (isPending) {
      return;
    }
    if (pathname.includes('account')) {
      AuthAnalytics.logout();
      logout();
    } else if (!isAuthenticated) {
      AuthAnalytics.joinRewards();
      navigate('/account/join');
    } else {
      AuthAnalytics.account();
      navigate('/account');
    }
  };

  return !pathname.includes('privacy') && !pathname.includes('terms') ? (
    <div className={styles.buttonBox} style={buttonsPositionStyles()}>
      <div onClick={onClick} className={styles.topButton}>
        {pathname.includes('account') ? (
          <span>LOG OUT</span>
        ) : isAuthenticated ? (
          <span>ACCOUNT</span>
        ) : (
          <span>JOIN REWARDS</span>
        )}
      </div>

      {!isAuthenticated && (
        <div className={styles.loginCopy}>
          <span>Already a member?</span>
          <Link to="/account/login" onClick={() => AuthAnalytics.login()}>
            Login
          </Link>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default TopButton;
