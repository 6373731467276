import React from 'react';
import styles from './PrivacyPolicy.module.scss';

import closeButton from '../../images/Button-Exit-White-Inactive.svg';
import TermsOfService from '../terms-of-service/TermsOfService';

export default class PrivacyPolicy extends React.Component<any> {
  state: any;
  constructor(props) {
    super(props);

    this.state = {
      hideTermsOfService: true,
    };
  }

  render() {
    if (this.props.hide) {
      return null;
    }

    return (
      <div>
        <div
          className={styles.backdrop}
          onClick={() => {
            this.props.hidePrivacyPolicy(true);
          }}
        >
          <div
            className={styles.modal}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className={styles.header}>
              <span></span>
              <img
                src={closeButton}
                alt="close button"
                onClick={() => {
                  this.props.hidePrivacyPolicy();
                }}
              ></img>
            </div>
            <div className={styles.modalBody}>
              <h1>
                PRIVACY POLICY <br></br> Effective Date: February 21, 2020.
              </h1>
              <p>This Privacy Policy was last updated on February 21, 2020.</p>
              <p>
                This privacy policy (“<b>Privacy Policy</b>”) describes how <b>VB Brands</b> (“
                <b>Company”</b> or “<b>us</b>” or “<b>we</b>” or “<b>our</b>”) collects, stores, and
                uses personal information of users of the website www.clickspray.com (the “
                <b>Site”</b>) and services supplied by or on behalf of us through the Site (“
                <b>Services</b>”). This Privacy Policy has been compiled to explain how information,
                including personal information, is collected, or used. By accessing, browsing, or
                otherwise using the Site and the Services, you agree to our collection, storage, use
                and disclosure of your information, as described in this Privacy Policy and the
                Terms of Service.
              </p>
              <p>
                All updates to this Privacy Policy are amended at the top of this page, with
                time-date reference for your convenience. Company reserves the right in its sole
                discretion to amend this Privacy Policy at any time, and you should regularly check
                this Privacy Policy for any amendments. This Privacy Policy applies to any of our
                other websites that post this Privacy Policy. This Privacy Policy does not apply to
                websites that post different privacy policies or statements.
              </p>
              <div className={styles.divider}></div>
              <h2>PRIVACY OF CHILDREN</h2>
              <p>
                <b>
                  Our Services are not intended for children. You must be 21 years of age to use the
                  Site and/or Services related to the Site. If we learn or have reason to suspect
                  that a user is under age 21, we will promptly delete any personal information in
                  that user’s account.
                </b>
              </p>
              <div className={styles.divider}></div>
              <h2>What is Personal Information?</h2>
              <p>
                <b>
                  For the purposes of this Privacy Policy, personal information means information
                  that identifies, relates to, describes, is reasonably capable of being associated
                  with, or could be linked, directly, or indirectly, with a particular individual or
                  household. Personal information does not include publicly available information.
                </b>
              </p>
              <div className={styles.divider}></div>
              <h2>What Personal Information is Collected?</h2>
              <p>
                <b>Information You Give Us.&nbsp;</b>
                When you contact us or opt-in by registering on the Site, utilizing our Services,
                registering to receive alerts or other information via email, text, or instant
                message, we may collect your‎ name, age, location address, email address, phone
                number, payment information, such as your credit or debit card number and other card
                information; billing and other account authentication information, products
                purchased, as well as other information that you directly give us. We collect your
                personal information for the purposes described in this Privacy Policy.
              </p>
              <p>
                <b>Information Automatically Collected.&nbsp;</b>
                We and our third-party partners may automatically log information about you and your
                computer. For example, when visiting our Site, we may log your computer operating
                system type, browser type, IP address, geolocation information, referring website
                from which you accessed the Site, pages you viewed on the Site, how long you spent
                on a page on the Site, access times and information about your use of and actions on
                our Site. We may collect analytics data, or use third-party analytics tools, to help
                us measure traffic and usage trends and to learn more about the demographics of our
                users. We will not collect any location information that you do not volunteer or
                enable. We will delete any location information that you request to delete.
              </p>
              <p>
                <b>Cookies.&nbsp;</b>
                We may use cookies from time to time. Cookies are small files that a site or its
                service provider transfers to your computer’s hard drive through your browser (if
                you allow) that enables the site’s or service provider’s systems to recognize your
                browser and capture and remember certain information. They are also used to help us
                understand your preferences based on previous or current site activity. We also use
                cookies to help us compile aggregate data about Site traffic and Site interaction so
                that we can offer better Site experiences and tools in the future.
              </p>
              <p>
                We may use cookies to:
                <ul>
                  <li>Understand and save users’ preferences for future visits; and</li>
                  <li>
                    Compile aggregate data about Site traffic and Site interactions in order to
                    offer better Site experiences and tools in the future.
                  </li>
                </ul>
              </p>
              <p>
                We may also use trusted third-party services that track this information on our
                behalf.
              </p>
              <p>
                You can choose to have your computer warn you each time a cookie is being sent, or
                you can choose to turn off all cookies. You do this through your browser settings.
                Because each browser is a little different, look at your browser’s “Help Menu” to
                learn the correct way to modify your cookies.
              </p>
              <p>
                If you turn cookies off, some of the features that make your Site experience more
                efficient may not function properly or otherwise be available to you.
              </p>
              <div className={styles.divider}></div>
              <h2>How do we Use Your Information?</h2>
              <p>
                We use the information and personal data provided by you to help us improve your
                experience on the Site. We may use your personal information we collect from you to:
                <ul>
                  <li>Improve our Services, functionality, and overall user experience;</li>
                  <li>
                    Better cater and market to our customers, as well as learn about purchasing
                    habits;
                  </li>
                  <li>Respond to your customer service requests, comments and questions;</li>
                  <li>Send periodic emails regarding our products and services;</li>
                  <li>
                    Follow up with you after correspondence (live chat, email, or phone inquiries);
                  </li>
                  <li>
                    Detect security incidents, protect against malicious, deceptive, fraudulent, or
                    illegal activity; or prosecute those responsible for that activity;
                  </li>
                  <li>Troubleshoot technical difficulties and errors;</li>
                  <li>Link or combine user information with other personal information;</li>
                  <li>
                    As permitted or required by applicable law or regulatory requirements; and
                  </li>
                  <li>
                    For any additional purposes for which we have obtained your consent to the use
                    or disclosure of your personal information.
                  </li>
                </ul>
              </p>
              <div className={styles.divider}></div>
              <h2>Who do we Provide Your Information to? </h2>
              <p>
                Except as disclosed in this Privacy Policy, we do not disclose your personal
                information to any companies not part of [Company], Inc. or its parent, subsidiaries
                or related entities. In no event will we ever sell or rent your personal
                information. We may share your personal information as follows:
                <ul>
                  <li>
                    We may share personal information if you opt-in to receiving our marketing
                    materials. For example, you may let us share personal information with our
                    partners and others for their own marketing uses. Those uses will be subject to
                    their privacy policies.
                  </li>
                  <li>
                    We may share your information with vendors, service providers, contractors and
                    agents in connection with the performance of services on our behalf. These
                    service providers are prohibited from using your information except as needed to
                    perform our work or as required by law.
                  </li>
                  <li>
                    We may share personal information when we do a business deal, or negotiate a
                    business deal, involving the sale or transfer of all or a part of our business
                    or assets. These deals can include any merger, financing, acquisition, or
                    bankruptcy transaction or proceeding.
                  </li>
                  <li>
                    We may share personal information for legal, protection, and safety purposes.
                  </li>
                  <li>We may share information to comply with laws or as permitted by laws.</li>
                  <li>
                    We may share information to respond to lawful requests and legal processes.
                  </li>
                  <li>
                    We may share information with other companies and brands owned or controlled by
                    [Company], Inc., including our subsidiaries.
                  </li>
                  <li>
                    We may share information to protect the rights and property of the Company, our
                    agents, customers, and others. This includes enforcing our agreements, policies,
                    and terms of use.
                  </li>
                  <li>
                    We may share information in an emergency. This includes protecting the safety of
                    our employees and agents, our customers, or any person.
                  </li>
                </ul>
              </p>
              <p>
                We may also share your personal information with our employees, contractors,
                consultants, affiliates and other parties who require such information to assist us
                with managing our relationship with you, including trusted third parties that
                provide services, including services to process your payments, to us or on our
                behalf. For example, we may share your personal information from time to time with
                our third-party information technology, data processing and call center service
                providers so that we may operate our business. Finally, we may also share aggregated
                and/or anonymized data with others for their own uses.
              </p>
              <p>
                We do not sell, trade, or otherwise transfer to outside parties your personal
                information outside of the situations detailed above, unless you consent otherwise.
              </p>
              <div className={styles.divider}></div>
              <h2>Security and Protection of Your Personal Data</h2>
              <p>
                We take the security of your personal information seriously and use reasonable
                electronic, personnel and physical measures to protect it from loss, theft,
                alteration or misuse. However, please be advised that even the best security
                measures cannot fully eliminate all risks. We cannot guarantee that only authorized
                persons will view your information. We are not responsible for third-party
                circumvention of any privacy settings or security measures.
              </p>
              <p>
                Wherever we or a trusted third-party service collects sensitive information (such as
                credit card data), that information is encrypted and transmitted in a secure way.
                You can verify this by looking for a closed lock icon at the bottom of your web
                browser or looking for “https” at the beginning of the address of the web page. When
                you make a purchase at our store with a credit card, our payment processor stores
                your credit card data, ensures that it is encrypted through PCI-DSS, then deletes
                the transaction information as soon as it is no longer necessary. We not transmit
                financial information by email.
              </p>
              <p>
                We are dedicated to protecting all personal information as is necessary. However,
                you are responsible for maintaining the confidentiality of your personal information
                by keeping your password confidential. You should change your password immediately
                if you believe someone has gained unauthorized access to it or your account. If you
                learn of any incident involving the loss of or unauthorized access to or disclosure
                of personal information that is in our custody, you should notify us immediately.
              </p>
              <div className={styles.divider}></div>
              <h2>Changes to this Policy</h2>
              <p>
                If we make changes to this Privacy Policy, we will post the updated Privacy Policy
                on the Site. We may also send emails to our users who have created an Account
                containing a link to the revised Privacy Policy.
              </p>
              <div className={styles.divider}></div>
              <h2>Consent</h2>
              <p>
                By submitting or making available Personal Information through our Site, you confirm
                that you have read and accepted the terms of this Privacy Policy and you expressly
                consent to the collection, storage, use, and disclosure of your Personal Information
                in accordance with this Privacy Policy. If you do not agree to the terms of this
                Privacy Policy, you must not use our Services or our Site.
              </p>
              <div className={styles.divider}></div>
              <h2>Updating your Personal Information</h2>
              <p>
                It is important that the information contained in our records is both accurate and
                current. If your personal information happens to change during the course of our
                relationship, please keep us informed of such changes.
              </p>
              <div className={styles.divider}></div>
              <h2>Right to Opt-Out of Sale of Personal Information </h2>
              <p>
                We do not sell, trade, otherwise transfer your personal information outside of the
                situations detailed above, so we do not have an opt-out.
              </p>
              <div className={styles.divider}></div>
              <h2>Access to Your Personal Information (“Request to Know”)</h2>
              <p>
                Subject to applicable laws, you can review, verify or correct your personal
                information. To submit a request to review, verify, or correct your personal
                information, email <b>hello@clickspray.com</b>. Only you, or a person legally
                authorized to act on your behalf (an “Authorized Agent”), may make a verifiable
                consumer request related to your personal information. You have the right to request
                this information up to two times in a 12-month period.
              </p>
              <div className={styles.divider}></div>
              <h2>Right to Request Deletion of Personal Information</h2>
              <p>
                You have the right to request deletion of your personal information. Only you, or a
                person authorized to act on your behalf (an “Authorized Agent”), may make a
                verifiable consumer request related to your personal information.
              </p>
              <div className={styles.divider}></div>
              <h2>Authorized Agents</h2>
              <p>
                If you use an Authorized Agent to submit a request to know or a request to delete,
                we may require the Authorized Agent to submit proof that they have been authorized
                to act on your behalf. We may accept as proof either:
                <ul>
                  <li>Written permission from you; or</li>
                  <li>
                    A power of attorney pursuant to the California Probate Code §§ 4000 to 4465.
                  </li>
                </ul>
              </p>
              <p>
                We may also require the Authorized Agent to verify their identity directly with us.
              </p>
              <div className={styles.divider}></div>
              <h2>Non-Discrimination</h2>
              <p>
                We will not discriminate against you for exercising any of your rights under the
                CCPA. Unless permitted by the CCPA, we will not:
                <ul>
                  <li>Deny you products or Services.</li>
                  <li>
                    Charge you different prices or rates for products or Services, including through
                    the use of discounts or other benefits or imposing penalties.
                  </li>
                  <li>Provide you with a different level or quality of products or Services.</li>
                  <li>
                    Suggest that you will receive a different price or rate for products or Services
                    or a different level or quality of products or Services.
                  </li>
                </ul>
              </p>
              <div className={styles.divider}></div>
              <h2>How to Unsubscribe or Change Your Preferences</h2>
              <p>
                You may unsubscribe or change your preferences by clicking on the “unsubscribe link”
                provided in such communications or by visiting [INSERT LINK HERE].
              </p>
              <div className={styles.divider}></div>
              <h2>Do Not Track Signals</h2>
              <p>
                We honor do not track signals and do not track, plant cookies or use advertising
                when a Do Not Track (DNT) browser mechanism is in place.
              </p>
              <div className={styles.divider}></div>
              <h2>Your California Privacy Rights</h2>
              <p>
                If you are a California resident, you have the following rights with respect to your
                personal data: 1) to update or amend personal data that we have collected about you
                if it is inaccurate or incomplete; (2) to request a copy of the personal information
                that we hold about you; (3) to request the categories of sources from which your
                personal information is collected; (4) to request the categories of third parties
                with whom we share personal information; (5) the commercial purpose for collecting
                your personal information; (6) to request the categories of personal information
                sold and the categories of third parties to whom the personal information was sold;
                (7) to request that we erase or restrict personal information; (8) to object to the
                processing of your personal information with respect to direct marketing purposes;
                and (9) the right not to receive discriminatory treatment for the exercise of your
                privacy rights. You have the right to request this information up to two times in a
                12-month period. You have the right to designate an Authorized Agent to make a
                request on your behalf.
              </p>
              <div className={styles.divider}></div>
              <h2>Your European Privacy Rights</h2>
              <p>
                If you are a data subject in Europe, you have the right to access, rectify, or erase
                any personal data we have collected about you through the Services. You also have
                the right to data portability and the right to restrict or object to our processing
                of personal data we have collected about you through the Services. In addition, you
                have the right to ask us not to process your personal data (or provide it to third
                parties to process) for marketing purposes or purposes materially different than for
                which it was originally collected or subsequently authorized by you. You may
                withdraw your consent at any time for any data processing we do based on consent you
                have provided to us.
              </p>
              <p>
                To exercise any of these rights, contact us at the address set forth in the section
                entitled “Contact Us” below and specify which right you intend to exercise. We will
                respond to your request within 30 days. We may require additional information from
                you to allow us to confirm your identity. Please note that we store information as
                necessary to fulfill the purposes for which it was collected, and may continue to
                retain and use the information even after a data subject request for purposes of our
                legitimate interests, including, as necessary, to comply with our legal obligations,
                resolve disputes, prevent fraud, and enforce our agreements.
              </p>
              <p>
                If you have any issues with our compliance, you have the right to lodge a complaint
                with a European supervisory authority.
              </p>
              <div className={styles.divider}></div>
              <h2>Terms of Service</h2>
              <p>
                This Privacy Policy is subject to the Terms of Service, a copy of which is
                available&nbsp;
                <span
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    this.setState({
                      hideTermsOfService: !this.state.hideTermsOfService,
                    })
                  }
                >
                  here
                </span>
                . It is important that you read the Terms of Service, as where there is a conflict
                between this Privacy Policy and the Terms of Service, the Terms of Service shall
                prevail.
              </p>
              <div className={styles.divider}></div>
              <h2>Accessibility</h2>
              <p>
                We recognize the need to develop electronic and information technology (E&IT)
                products and services that are accessible and usable by all people, including those
                with disabilities and special needs. We provide technical and customer support to
                accommodate the needs of users with disabilities and address issues related to the
                accessibility of VB Brands products.
              </p>
              <p>
                Our services include:
                <ul>
                  <li>
                    <b>Customer Technical Support</b> to answer general and/or technical questions
                    (888) 221-2554 <br></br>
                    Live Chat for the hearing impaired and other users <br></br>
                    Available Monday-Friday from 9:00 a.m. to 5:00 p.m. PST, excluding holidays
                  </li>
                  <li>
                    <b>General product information</b> (888) 221-2554
                  </li>
                  <li>
                    <b>Privacy Policy</b> (888) 221-2554
                  </li>
                  <li>
                    <b>Product Documentation</b>
                    Users may download free documentation, reference materials, and user guides at
                    INSERT LINK HERE (e.g. URL/documentation). In addition, users may request
                    product documentation and reference materials in alternate formats.
                  </li>
                </ul>
              </p>
              <div className={styles.divider}></div>
              <p>
                <b>Contact [Company] About the Privacy Policy</b>
              </p>
              <p>If you have any questions regarding this Privacy Policy, please contact:</p>
              <p>VB Brands</p>
              <p>Address: 520 Broadway, Santa Monica CA 90401</p>
              <p>Phone: (888) 221-2554</p>
              <p>E-mail: hello@clickspray.com</p>
            </div>
          </div>
        </div>
        <TermsOfService
          hide={this.state.hideTermsOfService}
          hideTermsOfService={() => this.setState({ hideTermsOfService: true })}
        ></TermsOfService>
      </div>
    );
  }
}
