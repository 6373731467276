import React from 'react';
import { isBrowser } from 'browser-or-node';
import styles from './AgeGate.module.scss';
import clickLogo from '../../../images/click-chill-logo.svg';

import PrivacyPolicy from '../../privacy-policy/PrivacyPolicy';
import TermsOfService from '../../terms-of-service/TermsOfService';

export default class AgeGate extends React.Component<any> {
  state: any;

  constructor(props) {
    super(props);
    this.state = {
      ageGateKey: true,
      rememberMe: false,
      hidePrivacyPolicy: true,
      hideTermsOfService: true,
    };
  }

  componentDidMount() {
    if (isBrowser) {
      if (!sessionStorage.getItem('AGE_GATE_KEY') && !localStorage.getItem('AGE_GATE_KEY')) {
        this.setState({ ageGateKey: false });
      }
    }
  }

  handleAgeGate = () => {
    if (this.state.rememberMe) {
      localStorage.setItem('AGE_GATE_KEY', true as any);
      this.props.setRewardsPopUpShown();
    } else {
      sessionStorage.setItem('AGE_GATE_KEY', true as any);
      this.props.setRewardsPopUpShown();
    }

    this.setState({
      ageGateKey: true,
    });
  };

  render() {
    if (this.state.ageGateKey) {
      return null;
    }

    return (
      <div>
        <div className={styles.popup}>
          <div className={styles.popupContent}>
            <img src={clickLogo} className={styles.clickLogo} alt="CLICK"></img>
            <div>ARE YOU AT LEAST 21?</div>
            <div className={styles.rememberMe}>
              <input
                type="checkbox"
                name="checkbox"
                id="checkbox"
                onChange={(e) => {
                  this.setState({ rememberMe: e.target.checked });
                }}
              ></input>
              <label htmlFor="checkbox">remember me</label>
            </div>
            <p>
              By clicking “I agree” and entering the website, I agree to be bound by the &nbsp;
              <span
                className={styles.rulesLink}
                onClick={() =>
                  this.setState({
                    hidePrivacyPolicy: !this.state.hidePrivacyPolicy,
                  })
                }
              >
                Privacy Policy
              </span>
              &nbsp; and &nbsp;
              <span
                className={styles.rulesLink}
                onClick={() =>
                  this.setState({
                    hideTermsOfService: !this.state.hideTermsOfService,
                  })
                }
              >
                Terms of Service
              </span>
              .
            </p>
            <div className={styles.popupButtonsContainer}>
              <button className={styles.button} onClick={this.handleAgeGate}>
                YES
              </button>
              <div className={styles.button}>NO</div>
            </div>
          </div>
        </div>

        <PrivacyPolicy
          hide={this.state.hidePrivacyPolicy}
          hidePrivacyPolicy={() => this.setState({ hidePrivacyPolicy: true })}
        ></PrivacyPolicy>
        <TermsOfService
          hide={this.state.hideTermsOfService}
          hideTermsOfService={() => this.setState({ hideTermsOfService: true })}
        ></TermsOfService>
      </div>
    );
  }
}
