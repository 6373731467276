import { Observer } from 'core/framework';

interface Engine {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
}

const FakeEngine: Engine = {
  getItem: (_key: string) => null,
  setItem: (_key: string) => {},
};

class StorageService extends Observer {
  private engine: Engine;
  private keyName: string;

  constructor(engine: Engine | undefined, keyName: string) {
    super();

    this.engine = engine ? engine : FakeEngine;
    this.keyName = keyName;

    this.registerListeners();
  }

  private registerListeners() {
    this.on('subscribe', () => {
      let oldValue = this.readRaw();

      const interval = setInterval(() => {
        if (this.readRaw() !== oldValue) {
          oldValue = this.readRaw();
          this.emit('update');
        }
      }, 100);

      this.on('unsubscribe', () => {
        clearInterval(interval);
      });
    });
  }

  private readRaw() {
    return this.engine.getItem(this.keyName);
  }

  read() {
    const readedValue = this.readRaw();

    try {
      return JSON.parse(readedValue as string);
    } catch (error) {
      return {};
    }
  }

  write(data: Object) {
    this.engine.setItem(this.keyName, JSON.stringify(data));
  }
}

export default StorageService;
