import { useLocation } from '@reach/router';
import classnames from 'classnames';
import { Link } from 'gatsby';
import React, { Component, useState } from 'react';

import { getLinkColorMobile } from '../side-nav/menu-color';

import useAuth from '../../hooks/useAuth';
import { RewardsUserType } from '../../services/enums';
import styles from './MobileNav.module.scss';
import AuthAnalytics from '../../core/framework/analytic/Auth';
import MarketService from 'core/services/marketService/MarketService';

interface ILink {
  name: string;
  onClick(): void;
}

interface Props {
  activeUrl: string;
  availableLinks?: ILink[];
  percentage: number;
}

const MobileNav: React.FC<Props> = ({ activeUrl, availableLinks, percentage }: Props) => {
  const [showOverlayMenu, setShowOverlayMenu] = useState(false);
  const [isActiveEcommerce, setIsActiveEcommerce] = useState(false);

  MarketService.getInstance()
    .isActiveMarket()
    .then((res) => {
      setIsActiveEcommerce(Boolean(res));
    });

  const location = useLocation();
  const {
    state: { profile },
    logout,
  } = useAuth();

  const showAccountMenu = location.pathname.includes('/account');

  const page =
    (activeUrl.includes('/blog') && 'blog') ||
    (activeUrl.includes('/about') && 'about') ||
    (activeUrl.includes('/events') && 'events') ||
    (activeUrl.includes('/account') && 'account') ||
    (activeUrl.includes('/products') && 'products') ||
    (activeUrl.includes('/resources') && 'resources') ||
    'home';

  const homeLinkColor = getLinkColorMobile(page, 'home', percentage);
  const menuLinkColor = getLinkColorMobile(page, 'menu', percentage);

  return (
    <nav className={classnames(styles.mobileNav, styles.overlay, showOverlayMenu && styles.open)}>
      <div>
        <Link to="https://clickspray.com" className={styles.logoIcon}>
          <svg data-name="Logo/Mark/White" width="33" height="35" viewBox="0 0 33 35">
            <g id="Click_logo-mark" transform="translate(0.254)">
              <path
                id="Path"
                d="M20.777,27.295a4.816,4.816,0,0,1-2.394-.627l-.16-.078a.477.477,0,0,1-.186-.131l-.106-.078a30.625,30.625,0,0,0-3.857-2.586,27.894,27.894,0,0,0-4.23-1.985l-.133-.052a.8.8,0,0,1-.213-.1l-.133-.078L9.231,21.5a4.792,4.792,0,0,1,0-8.358l.053-.026h0l.133-.078c.053-.026.133-.052.186-.078l.133-.052a30.665,30.665,0,0,0,4.23-1.985,29.233,29.233,0,0,0,3.884-2.612l.133-.1c.053-.052.133-.078.186-.131l.106-.052H18.3a5.041,5.041,0,0,1,2.447-.653,4.866,4.866,0,0,1,4.523,2.978,1.221,1.221,0,0,0,1.7.575c1.463-.81,3.591-1.985,4.895-2.69a1.2,1.2,0,0,0,.372-1.776,17.469,17.469,0,0,0-6.757-4.937h0l-.16-.078A19.139,19.139,0,0,0,17.665,0a17.766,17.766,0,0,0-12.5,4.937A17.153,17.153,0,0,0,0,17.4V17.6A17.153,17.153,0,0,0,5.161,30.063,17.766,17.766,0,0,0,17.665,35a19.139,19.139,0,0,0,7.662-1.437l.16-.078h0a17.862,17.862,0,0,0,6.757-4.937,1.2,1.2,0,0,0-.372-1.776c-1.33-.731-3.538-1.959-5.028-2.769a1.236,1.236,0,0,0-1.7.522A4.706,4.706,0,0,1,20.777,27.295Z"
                fill={homeLinkColor || 'white'}
              />
              <path
                id="Path-2"
                data-name="Path"
                d="M12.185,1.851A1.82,1.82,0,0,0,10.379,0a1.726,1.726,0,0,0-.9.257l-.05.026h0A30.677,30.677,0,0,1,5.39,3.136,30.208,30.208,0,0,1,.953,5.295h0L.9,5.321a1.858,1.858,0,0,0,0,3.187l.125.077h0a30.208,30.208,0,0,1,4.438,2.159A33.245,33.245,0,0,1,9.5,13.571h0a1.78,1.78,0,0,0,2.658-1.594v-.129a31.059,31.059,0,0,1-.376-5.012,31.094,31.094,0,0,1,.4-4.986Z"
                transform="translate(10.154 10.352)"
                fill={homeLinkColor || 'white'}
              />
            </g>
          </svg>
        </Link>

        <div
          className={classnames(styles.burgerButton, showOverlayMenu && styles.crossLines)}
          onClick={() => setShowOverlayMenu(!showOverlayMenu)}
          style={{ borderColor: menuLinkColor || 'white' }}
        >
          <div className={styles.line} style={{ backgroundColor: menuLinkColor || 'white' }}></div>
          <div className={styles.line} style={{ backgroundColor: menuLinkColor || 'white' }}></div>
          <div className={styles.line} style={{ backgroundColor: menuLinkColor || 'white' }}></div>
        </div>
      </div>

      {showOverlayMenu &&
        (availableLinks ? (
          <ul className={styles.menuBuild}>
            <MobileLink url="https://clickspray.com" title="HOME" number="01" />
            {availableLinks.map((item, index) => (
              <li
                key={`link${index}`}
                onClick={() => {
                  item.onClick();
                  setShowOverlayMenu(!showOverlayMenu);
                }}
              >
                <span className={styles.menuNumber}>0{index + 2}</span>
                <span>{item.name}</span>
              </li>
            ))}

            {isActiveEcommerce && <MobileLink url="https://clickspray.com/shop-cart-page/" title="BUY CLICK" number="07" />}

            <div onClick={logout}>
              <MobileLink url="https://clickspray.com" title="LOGOUT" number="08" />
            </div>
          </ul>
        ) : showAccountMenu ? (
          <ul>
            <MobileLink url="https://clickspray.com" title="HOME" number="01" />
            {profile?.rewardsUserType === RewardsUserType.budtender && (
              <React.Fragment>
                <MobileLink url="/account/product-info" title="PRODUCT INFO" number="02" />
                <MobileLink url="/account/invite-customer" title="INVITE" number="03" />
              </React.Fragment>
            )}
            <MobileLink
              url="/account"
              title="PROFILE"
              number={profile?.rewardsUserType === RewardsUserType.budtender ? '04' : '02'}
            />
            <div onClick={logout}>
              <MobileLink
                url="https://clickspray.com"
                title="LOGOUT"
                number={profile?.rewardsUserType === RewardsUserType.budtender ? '05' : '03'}
              />
            </div>
          </ul>
        ) : (
          <ul>
            <MobileLink url="https://clickspray.com" title="HOME" number="01" />
            <MobileLink
              url="https://clickspray.com/go/"
              title="PRODUCTS"
              number="02"
              activeUrl={activeUrl}
              partiallyActive={true}
            />
            <div onClick={() => setShowOverlayMenu(false)}>
              <MobileLink url="/#dispensaries" title="LOCATE" number="03" />
            </div>
            <MobileLink url="https://clickspray.com/about-page/" title="ABOUT" number="04" partiallyActive={true} />

            <MobileLink url="/shop/delivery" title="BUY CLICK" number="05" partiallyActive={true} />

            {/* <MobileLink url="/blog" title="BLOG" number="05" partiallyActive={true} /> */}
            {profile ? (
              <MobileLink url="/account" title="ACCOUNT" number="06" partiallyActive={true} />
            ) : (
              <>
                <MobileLink
                  url="/account/join"
                  title="JOIN REWARDS"
                  number="05"
                  partiallyActive={true}
                  onClick={() => AuthAnalytics.joinRewards()}
                />
                <MobileLink url="/account/login" title="LOGIN" number="06" partiallyActive={true} />
              </>
            )}
          </ul>
        ))}
    </nav>
  );
};

export default MobileNav;

interface MobileLinkProps {
  url: string;
  title: string;
  number: string;
  partiallyActive?: boolean;
  activeUrl?: string;
  onClick?: () => void;
}

class MobileLink extends Component<MobileLinkProps> {
  render() {
    const { url, title, number, partiallyActive, activeUrl, onClick } = this.props;

    return (
      <li>
        <Link
          to={url}
          className={activeUrl && activeUrl.includes('products') ? styles.active : ''}
          activeClassName={styles.active}
          partiallyActive={partiallyActive}
          onClick={onClick}
        >
          <span className={styles.menuNumber}>{number}</span>
          <span>{title}</span>
        </Link>
      </li>
    );
  }
}
