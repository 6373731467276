import { useReducer } from 'react';

class Store<State> implements Framework.Store<State> {
  private state: State;
  private reducers: {
    [key: string]: (state: State, pqyload: Framework.StoreActionPayload) => State;
  };

  constructor(initialState: State) {
    this.state = initialState;

    this.reducers = Object.getOwnPropertyNames(new.target.prototype)
      .filter((propertyName) => propertyName !== 'constructor')
      .reduce(
        (result, propertyName: string) => ({
          ...result,
          [propertyName]: Object.getOwnPropertyDescriptor(new.target.prototype, propertyName)
            ?.value,
        }),
        {},
      );

    this.useReducer = this.useReducer.bind(this);
  }

  public getState(): State {
    return this.state;
  }

  public setState(state: State) {
    this.state = state;
  }

  private reducer(state: State, action: Framework.Action): State {
    return this.reducers[action.type](state, action.payload);
  }

  public useReducer(): Framework.Hook<State> {
    const [state, dispatch] = useReducer(this.reducer.bind(this), this.state);

    return {
      state,
      dispatch: Object.keys(this.reducers).reduce(
        (result, type: string) => ({
          ...result,
          [type]: (payload: Framework.StoreActionPayload) => dispatch({ type, payload }),
        }),
        {},
      ),
    };
  }
}

export default Store;
