import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { isBrowser } from 'browser-or-node';

import StorageService from 'core/services/storageService/StorageService';
import OrderAnalytics from 'core/framework/analytic/OrderFlow';
import MarketService from 'core/services/marketService/MarketService';

import Delivery from 'components/shared/delivery-dialog/Delivery';

import styles from './ButtonBuy.module.scss';

function ButtonBuy() {
  const [show, setShow] = useState(false);
  const [storageService] = useState(
    new StorageService(isBrowser ? window.sessionStorage : undefined, 'shop/delivery'),
  );
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    MarketService.getInstance()
      .isActiveMarket()
      .then((res) => {
        setIsActive(Boolean(res));
      });
  }, []);

  const onSubmit = (data: Entities.PlaceDetails) => {
    storageService.write({
      ...storageService.read(),
      deliveries: [],
      destinations: [],
      placeDetails: data,
    });
    setTimeout(() => {
      navigate('/shop/delivery');
    }, 100);
  };

  return isActive ? (
    <>
      {/* <button
        type="button"
        className={styles.buttonBuy}
        onClick={() => {
          setShow(true);
          // window.location.href = 'https://cb.tryclick.us/';
          OrderAnalytics.buyClick();
        }}
      >
        Buy Click
      </button> */}
      <a className={styles.buttonBuy} href="https://clickspray.com/shop-cart-page/" onClick={() => {

          OrderAnalytics.buyClick();
        }} >Buy Click</a>

      <Delivery
        show={show}
        goToLocator={onSubmit}
        onSubmit={onSubmit}
        onHide={() => setShow(false)}
        successEvent={() => OrderAnalytics.chooseAddressHome()}
      />
    </>
  ) : null;
}

export default ButtonBuy;
