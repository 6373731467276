import React from 'react';

function Module(presenter: any, View: React.ComponentType<any>) {
  return (props: any) => {
    presenter.setInitialConfig(props);

    return <View usePresenter={presenter.usePresenter} />;
  };
}

export default Module;
