import autoBind from 'auto-bind';

abstract class Presenter<M, S, C> {
  model: M;
  store: Framework.Store<S>;
  state: S;
  dispatch: Framework.Dispatch = {};
  config: C | any;

  constructor(options: Framework.PresenterOptions<M, Framework.Store<S>>) {
    this.model = options.dependencies.model;
    this.store = options.dependencies.store;

    this.state = this.store.getState();

    autoBind(this);
  }

  public setInitialConfig(config: C): Presenter<M, S, C> {
    this.config = config;

    return this;
  }

  public abstract usePresenter(): Framework.PresenterHook<S>;
}

export default Presenter;
