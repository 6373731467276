import React, { FC, useState, useEffect } from 'react';

import styles from './rewardsPopUp.module.scss';
import Logo from '../../../images/awardsPopUp/Combined_Shape.png';
import { navigate } from 'gatsby';
import useAuth from '../../../hooks/useAuth';
import CrossImageFile from 'images/cross.svg';

const RewardsPopUp: FC = () => {
  const [isPopUpHidden, setIsPopUpHidden] = useState(false);
  const {
    state: { isAuthenticated },
  } = useAuth();

  const onClick = async () => {
    navigate('/account/join');
    document.body.style.position = 'static';
  };

  // useEffect(() => {
  //   !isPopUpHidden && !isAuthenticated
  //     ? (document.body.style.position = 'fixed')
  //     : (document.body.style.position = 'static');
  // }, [isPopUpHidden]);

  return (
    <>
      {isPopUpHidden ||
        (!window.location.pathname.includes('cart') && !isAuthenticated && (
          <section className={styles.wrapper}>
            <div className={styles.overlay}></div>

            <div className={styles.innerBlock}>
              <div className={styles.close} onClick={() => setIsPopUpHidden(true)}>
                <img src={CrossImageFile} alt="close" />
              </div>
              <p className={styles.closeMobile} onClick={() => setIsPopUpHidden(true)}>
                Skip for now
              </p>
              <div className={styles.whiteBlock}>
                <div className={styles.coloredBlock}>
                  <img src={Logo} alt="logo" className={styles.logo} />
                  <div className={styles.gradientBlock}></div>
                </div>
                <p className={styles.bigText}>Join CLICKS Rewards</p>
                <p className={styles.smallText}>Earn Points. Enjoy Rewards.</p>
                <div className={styles.joinButton} onClick={onClick}>
                  <p>Join Today</p>
                </div>
              </div>
            </div>
          </section>
        ))}
    </>
  );
};

export default RewardsPopUp;
